const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'brand') {
    return `/brands/${doc.uid}`
  }

  // URL for a product type
  if (doc.type === 'page') {
    return `/${doc.uid}`
  }

  // Backup for all other types
  return '/'
}

module.exports = linkResolver
