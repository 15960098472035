import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import { linkResolver } from '../utils/linkResolver'
import Layout from '../components/Layout'

const PreviewPage = ({ isPreview, isLoading }) => {
  const previewText = isPreview ? 'This is a preview!' : 'Not a preview!'
  const loadingText = isLoading ? 'Still Loading' : 'Not Loading!'
  return (
    <Layout>
      <p>{previewText}</p>
      <p>{loadingText}</p>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPage, {
   repositoryName: 'adjmi',
   linkResolver
})
